@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Roboto Light', 'Roboto Medium', 'Roboto', etc;
  scroll-behavior: smooth;
}
html {
  font-size: 62.5%;
}
body {
  background-color: #202026;
  font-size: 16px;
}

.fondo:before {
	content:'';
	position: absolute;
        top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);

}


.containerParticles {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #000000;
}

.background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
          mask-image: radial-gradient(white 0%, white 30%, transparent 80%, transparent);
}

.circle-container {
  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#ffffff, #ffffff 10%, rgba(153, 255, 255, 0) 56%);
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circle-container:nth-child(1) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-1;
  animation-duration: 31629ms;
  animation-delay: 16888ms;
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(95vw, 105vh, 0);
  }
  to {
    transform: translate3d(17vw, -109vh, 0);
  }
}
.circle-container:nth-child(1) .circle {
  animation-delay: 3101ms;
}
.circle-container:nth-child(2) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-2;
  animation-duration: 29829ms;
  animation-delay: 28802ms;
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(34vw, 108vh, 0);
  }
  to {
    transform: translate3d(81vw, -134vh, 0);
  }
}
.circle-container:nth-child(2) .circle {
  animation-delay: 2865ms;
}
.circle-container:nth-child(3) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-3;
  animation-duration: 31166ms;
  animation-delay: 26856ms;
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }
  to {
    transform: translate3d(41vw, -133vh, 0);
  }
}
.circle-container:nth-child(3) .circle {
  animation-delay: 3469ms;
}
.circle-container:nth-child(4) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-4;
  animation-duration: 31210ms;
  animation-delay: 3816ms;
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(29vw, 101vh, 0);
  }
  to {
    transform: translate3d(47vw, -103vh, 0);
  }
}
.circle-container:nth-child(4) .circle {
  animation-delay: 3947ms;
}
.circle-container:nth-child(5) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-5;
  animation-duration: 32610ms;
  animation-delay: 12936ms;
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(20vw, 104vh, 0);
  }
  to {
    transform: translate3d(76vw, -131vh, 0);
  }
}
.circle-container:nth-child(5) .circle {
  animation-delay: 554ms;
}
.circle-container:nth-child(6) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-6;
  animation-duration: 36741ms;
  animation-delay: 16650ms;
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(40vw, 102vh, 0);
  }
  to {
    transform: translate3d(6vw, -117vh, 0);
  }
}
.circle-container:nth-child(6) .circle {
  animation-delay: 2052ms;
}
.circle-container:nth-child(7) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-7;
  animation-duration: 32297ms;
  animation-delay: 33610ms;
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(23vw, -117vh, 0);
  }
}
.circle-container:nth-child(7) .circle {
  animation-delay: 347ms;
}
.circle-container:nth-child(8) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-8;
  animation-duration: 30947ms;
  animation-delay: 16143ms;
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(6vw, 107vh, 0);
  }
  to {
    transform: translate3d(42vw, -135vh, 0);
  }
}
.circle-container:nth-child(8) .circle {
  animation-delay: 492ms;
}
.circle-container:nth-child(9) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-9;
  animation-duration: 31436ms;
  animation-delay: 23328ms;
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(86vw, 101vh, 0);
  }
  to {
    transform: translate3d(71vw, -104vh, 0);
  }
}
.circle-container:nth-child(9) .circle {
  animation-delay: 1387ms;
}
.circle-container:nth-child(10) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-10;
  animation-duration: 33449ms;
  animation-delay: 26386ms;
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(68vw, 109vh, 0);
  }
  to {
    transform: translate3d(33vw, -115vh, 0);
  }
}
.circle-container:nth-child(10) .circle {
  animation-delay: 3979ms;
}
.circle-container:nth-child(11) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-11;
  animation-duration: 28278ms;
  animation-delay: 32831ms;
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(24vw, 104vh, 0);
  }
  to {
    transform: translate3d(84vw, -120vh, 0);
  }
}
.circle-container:nth-child(11) .circle {
  animation-delay: 3046ms;
}
.circle-container:nth-child(12) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-12;
  animation-duration: 30459ms;
  animation-delay: 164ms;
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(53vw, 109vh, 0);
  }
  to {
    transform: translate3d(74vw, -134vh, 0);
  }
}
.circle-container:nth-child(12) .circle {
  animation-delay: 299ms;
}
.circle-container:nth-child(13) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-13;
  animation-duration: 33610ms;
  animation-delay: 24288ms;
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(88vw, 110vh, 0);
  }
  to {
    transform: translate3d(44vw, -113vh, 0);
  }
}
.circle-container:nth-child(13) .circle {
  animation-delay: 2418ms;
}
.circle-container:nth-child(14) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-14;
  animation-duration: 34941ms;
  animation-delay: 15658ms;
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(45vw, 110vh, 0);
  }
  to {
    transform: translate3d(25vw, -127vh, 0);
  }
}
.circle-container:nth-child(14) .circle {
  animation-delay: 1331ms;
}
.circle-container:nth-child(15) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-15;
  animation-duration: 36241ms;
  animation-delay: 12834ms;
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(54vw, -129vh, 0);
  }
}
.circle-container:nth-child(15) .circle {
  animation-delay: 691ms;
}
.circle-container:nth-child(16) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-16;
  animation-duration: 35626ms;
  animation-delay: 24426ms;
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(54vw, 109vh, 0);
  }
  to {
    transform: translate3d(39vw, -126vh, 0);
  }
}
.circle-container:nth-child(16) .circle {
  animation-delay: 2272ms;
}
.circle-container:nth-child(17) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-17;
  animation-duration: 32811ms;
  animation-delay: 17578ms;
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -137vh, 0);
  }
}
.circle-container:nth-child(17) .circle {
  animation-delay: 2393ms;
}
.circle-container:nth-child(18) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-18;
  animation-duration: 30193ms;
  animation-delay: 2509ms;
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(67vw, 106vh, 0);
  }
  to {
    transform: translate3d(78vw, -110vh, 0);
  }
}
.circle-container:nth-child(18) .circle {
  animation-delay: 810ms;
}
.circle-container:nth-child(19) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-19;
  animation-duration: 33583ms;
  animation-delay: 7279ms;
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(55vw, 108vh, 0);
  }
  to {
    transform: translate3d(48vw, -132vh, 0);
  }
}
.circle-container:nth-child(19) .circle {
  animation-delay: 1112ms;
}
.circle-container:nth-child(20) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-20;
  animation-duration: 36573ms;
  animation-delay: 24154ms;
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(4vw, -108vh, 0);
  }
}
.circle-container:nth-child(20) .circle {
  animation-delay: 3899ms;
}
.circle-container:nth-child(21) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-21;
  animation-duration: 34467ms;
  animation-delay: 19420ms;
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(27vw, -116vh, 0);
  }
}
.circle-container:nth-child(21) .circle {
  animation-delay: 898ms;
}
.circle-container:nth-child(22) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-22;
  animation-duration: 33750ms;
  animation-delay: 17945ms;
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(27vw, 110vh, 0);
  }
  to {
    transform: translate3d(9vw, -128vh, 0);
  }
}
.circle-container:nth-child(22) .circle {
  animation-delay: 368ms;
}
.circle-container:nth-child(23) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-23;
  animation-duration: 36039ms;
  animation-delay: 31727ms;
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(92vw, 107vh, 0);
  }
  to {
    transform: translate3d(51vw, -112vh, 0);
  }
}
.circle-container:nth-child(23) .circle {
  animation-delay: 294ms;
}
.circle-container:nth-child(24) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-24;
  animation-duration: 33509ms;
  animation-delay: 23140ms;
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(95vw, 108vh, 0);
  }
  to {
    transform: translate3d(66vw, -112vh, 0);
  }
}
.circle-container:nth-child(24) .circle {
  animation-delay: 1317ms;
}
.circle-container:nth-child(25) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-25;
  animation-duration: 31384ms;
  animation-delay: 24586ms;
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(66vw, -122vh, 0);
  }
}
.circle-container:nth-child(25) .circle {
  animation-delay: 2095ms;
}
.circle-container:nth-child(26) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-26;
  animation-duration: 36752ms;
  animation-delay: 16778ms;
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(80vw, 109vh, 0);
  }
  to {
    transform: translate3d(30vw, -118vh, 0);
  }
}
.circle-container:nth-child(26) .circle {
  animation-delay: 3628ms;
}
.circle-container:nth-child(27) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-27;
  animation-duration: 30110ms;
  animation-delay: 19386ms;
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(18vw, -129vh, 0);
  }
}
.circle-container:nth-child(27) .circle {
  animation-delay: 1307ms;
}
.circle-container:nth-child(28) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-28;
  animation-duration: 34011ms;
  animation-delay: 11928ms;
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(86vw, 109vh, 0);
  }
  to {
    transform: translate3d(66vw, -118vh, 0);
  }
}
.circle-container:nth-child(28) .circle {
  animation-delay: 715ms;
}
.circle-container:nth-child(29) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-29;
  animation-duration: 30318ms;
  animation-delay: 21581ms;
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(23vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -117vh, 0);
  }
}
.circle-container:nth-child(29) .circle {
  animation-delay: 3520ms;
}
.circle-container:nth-child(30) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-30;
  animation-duration: 34903ms;
  animation-delay: 24248ms;
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(93vw, 108vh, 0);
  }
  to {
    transform: translate3d(84vw, -110vh, 0);
  }
}
.circle-container:nth-child(30) .circle {
  animation-delay: 1356ms;
}
.circle-container:nth-child(31) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-31;
  animation-duration: 32399ms;
  animation-delay: 20050ms;
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(49vw, 104vh, 0);
  }
  to {
    transform: translate3d(75vw, -112vh, 0);
  }
}
.circle-container:nth-child(31) .circle {
  animation-delay: 2541ms;
}
.circle-container:nth-child(32) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-32;
  animation-duration: 33153ms;
  animation-delay: 30498ms;
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(58vw, 101vh, 0);
  }
  to {
    transform: translate3d(74vw, -126vh, 0);
  }
}
.circle-container:nth-child(32) .circle {
  animation-delay: 3442ms;
}
.circle-container:nth-child(33) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-33;
  animation-duration: 29403ms;
  animation-delay: 11454ms;
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(98vw, 107vh, 0);
  }
  to {
    transform: translate3d(13vw, -113vh, 0);
  }
}
.circle-container:nth-child(33) .circle {
  animation-delay: 1920ms;
}
.circle-container:nth-child(34) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-34;
  animation-duration: 28231ms;
  animation-delay: 1053ms;
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(35vw, 107vh, 0);
  }
  to {
    transform: translate3d(88vw, -117vh, 0);
  }
}
.circle-container:nth-child(34) .circle {
  animation-delay: 1221ms;
}
.circle-container:nth-child(35) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-35;
  animation-duration: 36199ms;
  animation-delay: 10397ms;
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(34vw, 106vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
.circle-container:nth-child(35) .circle {
  animation-delay: 585ms;
}
.circle-container:nth-child(36) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-36;
  animation-duration: 29696ms;
  animation-delay: 29037ms;
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(18vw, 104vh, 0);
  }
  to {
    transform: translate3d(49vw, -114vh, 0);
  }
}
.circle-container:nth-child(36) .circle {
  animation-delay: 1659ms;
}
.circle-container:nth-child(37) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-37;
  animation-duration: 28679ms;
  animation-delay: 11272ms;
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(3vw, 102vh, 0);
  }
  to {
    transform: translate3d(5vw, -122vh, 0);
  }
}
.circle-container:nth-child(37) .circle {
  animation-delay: 1872ms;
}
.circle-container:nth-child(38) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-38;
  animation-duration: 33170ms;
  animation-delay: 36129ms;
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(40vw, 101vh, 0);
  }
  to {
    transform: translate3d(65vw, -113vh, 0);
  }
}
.circle-container:nth-child(38) .circle {
  animation-delay: 2034ms;
}
.circle-container:nth-child(39) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-39;
  animation-duration: 32383ms;
  animation-delay: 12852ms;
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }
  to {
    transform: translate3d(55vw, -126vh, 0);
  }
}
.circle-container:nth-child(39) .circle {
  animation-delay: 710ms;
}
.circle-container:nth-child(40) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-40;
  animation-duration: 36807ms;
  animation-delay: 7650ms;
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(42vw, 108vh, 0);
  }
  to {
    transform: translate3d(93vw, -124vh, 0);
  }
}
.circle-container:nth-child(40) .circle {
  animation-delay: 459ms;
}
.circle-container:nth-child(41) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-41;
  animation-duration: 34570ms;
  animation-delay: 35125ms;
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(96vw, 102vh, 0);
  }
  to {
    transform: translate3d(26vw, -128vh, 0);
  }
}
.circle-container:nth-child(41) .circle {
  animation-delay: 3205ms;
}
.circle-container:nth-child(42) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-42;
  animation-duration: 31428ms;
  animation-delay: 24853ms;
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(25vw, 102vh, 0);
  }
  to {
    transform: translate3d(67vw, -108vh, 0);
  }
}
.circle-container:nth-child(42) .circle {
  animation-delay: 3428ms;
}
.circle-container:nth-child(43) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-43;
  animation-duration: 29719ms;
  animation-delay: 9547ms;
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(58vw, 107vh, 0);
  }
  to {
    transform: translate3d(88vw, -131vh, 0);
  }
}
.circle-container:nth-child(43) .circle {
  animation-delay: 1600ms;
}
.circle-container:nth-child(44) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-44;
  animation-duration: 30605ms;
  animation-delay: 21395ms;
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(96vw, 106vh, 0);
  }
  to {
    transform: translate3d(16vw, -112vh, 0);
  }
}
.circle-container:nth-child(44) .circle {
  animation-delay: 3918ms;
}
.circle-container:nth-child(45) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-45;
  animation-duration: 36914ms;
  animation-delay: 13268ms;
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(62vw, 104vh, 0);
  }
  to {
    transform: translate3d(41vw, -117vh, 0);
  }
}
.circle-container:nth-child(45) .circle {
  animation-delay: 1167ms;
}
.circle-container:nth-child(46) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-46;
  animation-duration: 36798ms;
  animation-delay: 1119ms;
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(47vw, -107vh, 0);
  }
}
.circle-container:nth-child(46) .circle {
  animation-delay: 968ms;
}
.circle-container:nth-child(47) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-47;
  animation-duration: 33653ms;
  animation-delay: 18314ms;
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(83vw, 110vh, 0);
  }
  to {
    transform: translate3d(98vw, -113vh, 0);
  }
}
.circle-container:nth-child(47) .circle {
  animation-delay: 3988ms;
}
.circle-container:nth-child(48) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-48;
  animation-duration: 29667ms;
  animation-delay: 7239ms;
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(55vw, 102vh, 0);
  }
  to {
    transform: translate3d(40vw, -112vh, 0);
  }
}
.circle-container:nth-child(48) .circle {
  animation-delay: 2746ms;
}
.circle-container:nth-child(49) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-49;
  animation-duration: 29225ms;
  animation-delay: 1566ms;
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(62vw, 105vh, 0);
  }
  to {
    transform: translate3d(48vw, -134vh, 0);
  }
}
.circle-container:nth-child(49) .circle {
  animation-delay: 71ms;
}
.circle-container:nth-child(50) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-50;
  animation-duration: 35531ms;
  animation-delay: 3663ms;
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(35vw, 106vh, 0);
  }
  to {
    transform: translate3d(73vw, -129vh, 0);
  }
}
.circle-container:nth-child(50) .circle {
  animation-delay: 134ms;
}
.circle-container:nth-child(51) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-51;
  animation-duration: 33573ms;
  animation-delay: 11807ms;
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -125vh, 0);
  }
}
.circle-container:nth-child(51) .circle {
  animation-delay: 547ms;
}
.circle-container:nth-child(52) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-52;
  animation-duration: 36694ms;
  animation-delay: 23819ms;
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(90vw, -107vh, 0);
  }
}
.circle-container:nth-child(52) .circle {
  animation-delay: 1363ms;
}
.circle-container:nth-child(53) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-53;
  animation-duration: 36407ms;
  animation-delay: 16269ms;
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }
  to {
    transform: translate3d(12vw, -117vh, 0);
  }
}
.circle-container:nth-child(53) .circle {
  animation-delay: 1533ms;
}
.circle-container:nth-child(54) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-54;
  animation-duration: 28720ms;
  animation-delay: 17800ms;
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(6vw, 109vh, 0);
  }
  to {
    transform: translate3d(2vw, -111vh, 0);
  }
}
.circle-container:nth-child(54) .circle {
  animation-delay: 1903ms;
}
.circle-container:nth-child(55) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-55;
  animation-duration: 30211ms;
  animation-delay: 14858ms;
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(95vw, 110vh, 0);
  }
  to {
    transform: translate3d(34vw, -120vh, 0);
  }
}
.circle-container:nth-child(55) .circle {
  animation-delay: 2104ms;
}
.circle-container:nth-child(56) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-56;
  animation-duration: 36297ms;
  animation-delay: 15556ms;
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(59vw, 105vh, 0);
  }
  to {
    transform: translate3d(97vw, -130vh, 0);
  }
}
.circle-container:nth-child(56) .circle {
  animation-delay: 1746ms;
}
.circle-container:nth-child(57) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-57;
  animation-duration: 31703ms;
  animation-delay: 12ms;
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(11vw, 109vh, 0);
  }
  to {
    transform: translate3d(4vw, -116vh, 0);
  }
}
.circle-container:nth-child(57) .circle {
  animation-delay: 527ms;
}
.circle-container:nth-child(58) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-58;
  animation-duration: 36240ms;
  animation-delay: 5124ms;
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(62vw, 104vh, 0);
  }
  to {
    transform: translate3d(21vw, -125vh, 0);
  }
}
.circle-container:nth-child(58) .circle {
  animation-delay: 1778ms;
}
.circle-container:nth-child(59) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-59;
  animation-duration: 30622ms;
  animation-delay: 26262ms;
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(38vw, 104vh, 0);
  }
  to {
    transform: translate3d(25vw, -129vh, 0);
  }
}
.circle-container:nth-child(59) .circle {
  animation-delay: 3699ms;
}
.circle-container:nth-child(60) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-60;
  animation-duration: 35898ms;
  animation-delay: 5248ms;
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(51vw, 109vh, 0);
  }
  to {
    transform: translate3d(62vw, -119vh, 0);
  }
}
.circle-container:nth-child(60) .circle {
  animation-delay: 562ms;
}
.circle-container:nth-child(61) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-61;
  animation-duration: 28302ms;
  animation-delay: 4624ms;
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(25vw, 110vh, 0);
  }
  to {
    transform: translate3d(87vw, -138vh, 0);
  }
}
.circle-container:nth-child(61) .circle {
  animation-delay: 3027ms;
}
.circle-container:nth-child(62) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-62;
  animation-duration: 30400ms;
  animation-delay: 17655ms;
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(83vw, 107vh, 0);
  }
  to {
    transform: translate3d(46vw, -122vh, 0);
  }
}
.circle-container:nth-child(62) .circle {
  animation-delay: 1984ms;
}
.circle-container:nth-child(63) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-63;
  animation-duration: 32137ms;
  animation-delay: 30436ms;
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(97vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -121vh, 0);
  }
}
.circle-container:nth-child(63) .circle {
  animation-delay: 3403ms;
}
.circle-container:nth-child(64) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-64;
  animation-duration: 31419ms;
  animation-delay: 20043ms;
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(95vw, -117vh, 0);
  }
}
.circle-container:nth-child(64) .circle {
  animation-delay: 2595ms;
}
.circle-container:nth-child(65) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-65;
  animation-duration: 29483ms;
  animation-delay: 9788ms;
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(47vw, 104vh, 0);
  }
  to {
    transform: translate3d(22vw, -131vh, 0);
  }
}
.circle-container:nth-child(65) .circle {
  animation-delay: 6ms;
}
.circle-container:nth-child(66) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-66;
  animation-duration: 31370ms;
  animation-delay: 26064ms;
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(70vw, 101vh, 0);
  }
  to {
    transform: translate3d(87vw, -105vh, 0);
  }
}
.circle-container:nth-child(66) .circle {
  animation-delay: 2311ms;
}
.circle-container:nth-child(67) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-67;
  animation-duration: 29878ms;
  animation-delay: 11458ms;
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(81vw, 109vh, 0);
  }
  to {
    transform: translate3d(26vw, -110vh, 0);
  }
}
.circle-container:nth-child(67) .circle {
  animation-delay: 1072ms;
}
.circle-container:nth-child(68) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-68;
  animation-duration: 32412ms;
  animation-delay: 30363ms;
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(36vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -122vh, 0);
  }
}
.circle-container:nth-child(68) .circle {
  animation-delay: 3212ms;
}
.circle-container:nth-child(69) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-69;
  animation-duration: 35373ms;
  animation-delay: 32852ms;
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(9vw, 108vh, 0);
  }
  to {
    transform: translate3d(68vw, -121vh, 0);
  }
}
.circle-container:nth-child(69) .circle {
  animation-delay: 2847ms;
}
.circle-container:nth-child(70) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-70;
  animation-duration: 31004ms;
  animation-delay: 6066ms;
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(44vw, 110vh, 0);
  }
  to {
    transform: translate3d(41vw, -116vh, 0);
  }
}
.circle-container:nth-child(70) .circle {
  animation-delay: 3412ms;
}
.circle-container:nth-child(71) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-71;
  animation-duration: 29439ms;
  animation-delay: 18936ms;
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(89vw, 102vh, 0);
  }
  to {
    transform: translate3d(81vw, -127vh, 0);
  }
}
.circle-container:nth-child(71) .circle {
  animation-delay: 391ms;
}
.circle-container:nth-child(72) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-72;
  animation-duration: 34318ms;
  animation-delay: 8687ms;
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(58vw, 109vh, 0);
  }
  to {
    transform: translate3d(17vw, -119vh, 0);
  }
}
.circle-container:nth-child(72) .circle {
  animation-delay: 2082ms;
}
.circle-container:nth-child(73) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-73;
  animation-duration: 34056ms;
  animation-delay: 29022ms;
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(72vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -113vh, 0);
  }
}
.circle-container:nth-child(73) .circle {
  animation-delay: 1105ms;
}
.circle-container:nth-child(74) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-74;
  animation-duration: 29818ms;
  animation-delay: 19782ms;
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -119vh, 0);
  }
}
.circle-container:nth-child(74) .circle {
  animation-delay: 3544ms;
}
.circle-container:nth-child(75) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-75;
  animation-duration: 32961ms;
  animation-delay: 34368ms;
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }
  to {
    transform: translate3d(76vw, -110vh, 0);
  }
}
.circle-container:nth-child(75) .circle {
  animation-delay: 1337ms;
}
.circle-container:nth-child(76) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-76;
  animation-duration: 29295ms;
  animation-delay: 2855ms;
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(46vw, 101vh, 0);
  }
  to {
    transform: translate3d(28vw, -102vh, 0);
  }
}
.circle-container:nth-child(76) .circle {
  animation-delay: 1601ms;
}
.circle-container:nth-child(77) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-77;
  animation-duration: 34991ms;
  animation-delay: 1743ms;
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(80vw, 107vh, 0);
  }
  to {
    transform: translate3d(31vw, -130vh, 0);
  }
}
.circle-container:nth-child(77) .circle {
  animation-delay: 1663ms;
}
.circle-container:nth-child(78) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-78;
  animation-duration: 32986ms;
  animation-delay: 8894ms;
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(55vw, 107vh, 0);
  }
  to {
    transform: translate3d(38vw, -115vh, 0);
  }
}
.circle-container:nth-child(78) .circle {
  animation-delay: 2193ms;
}
.circle-container:nth-child(79) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-79;
  animation-duration: 31915ms;
  animation-delay: 10350ms;
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(98vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -119vh, 0);
  }
}
.circle-container:nth-child(79) .circle {
  animation-delay: 2273ms;
}
.circle-container:nth-child(80) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-80;
  animation-duration: 32368ms;
  animation-delay: 24668ms;
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(97vw, 110vh, 0);
  }
  to {
    transform: translate3d(9vw, -135vh, 0);
  }
}
.circle-container:nth-child(80) .circle {
  animation-delay: 686ms;
}
.circle-container:nth-child(81) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-81;
  animation-duration: 34007ms;
  animation-delay: 23002ms;
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(51vw, 106vh, 0);
  }
  to {
    transform: translate3d(59vw, -128vh, 0);
  }
}
.circle-container:nth-child(81) .circle {
  animation-delay: 3166ms;
}
.circle-container:nth-child(82) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-82;
  animation-duration: 36481ms;
  animation-delay: 25269ms;
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(50vw, 103vh, 0);
  }
  to {
    transform: translate3d(51vw, -131vh, 0);
  }
}
.circle-container:nth-child(82) .circle {
  animation-delay: 3447ms;
}
.circle-container:nth-child(83) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-83;
  animation-duration: 36454ms;
  animation-delay: 26211ms;
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(44vw, 107vh, 0);
  }
  to {
    transform: translate3d(95vw, -129vh, 0);
  }
}
.circle-container:nth-child(83) .circle {
  animation-delay: 108ms;
}
.circle-container:nth-child(84) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-84;
  animation-duration: 30665ms;
  animation-delay: 27709ms;
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(83vw, -128vh, 0);
  }
}
.circle-container:nth-child(84) .circle {
  animation-delay: 1816ms;
}
.circle-container:nth-child(85) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-85;
  animation-duration: 31266ms;
  animation-delay: 24900ms;
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(2vw, 102vh, 0);
  }
  to {
    transform: translate3d(27vw, -130vh, 0);
  }
}
.circle-container:nth-child(85) .circle {
  animation-delay: 3589ms;
}
.circle-container:nth-child(86) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-86;
  animation-duration: 29420ms;
  animation-delay: 25152ms;
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(82vw, 107vh, 0);
  }
  to {
    transform: translate3d(90vw, -135vh, 0);
  }
}
.circle-container:nth-child(86) .circle {
  animation-delay: 1075ms;
}
.circle-container:nth-child(87) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-87;
  animation-duration: 33200ms;
  animation-delay: 22278ms;
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(82vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -124vh, 0);
  }
}
.circle-container:nth-child(87) .circle {
  animation-delay: 1474ms;
}
.circle-container:nth-child(88) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-88;
  animation-duration: 29265ms;
  animation-delay: 29935ms;
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(49vw, -122vh, 0);
  }
}
.circle-container:nth-child(88) .circle {
  animation-delay: 972ms;
}
.circle-container:nth-child(89) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-89;
  animation-duration: 35965ms;
  animation-delay: 11285ms;
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(81vw, 107vh, 0);
  }
  to {
    transform: translate3d(41vw, -111vh, 0);
  }
}
.circle-container:nth-child(89) .circle {
  animation-delay: 2073ms;
}
.circle-container:nth-child(90) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-90;
  animation-duration: 31881ms;
  animation-delay: 1489ms;
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(7vw, 102vh, 0);
  }
  to {
    transform: translate3d(98vw, -118vh, 0);
  }
}
.circle-container:nth-child(90) .circle {
  animation-delay: 2194ms;
}
.circle-container:nth-child(91) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-91;
  animation-duration: 28187ms;
  animation-delay: 14219ms;
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(55vw, 107vh, 0);
  }
  to {
    transform: translate3d(75vw, -127vh, 0);
  }
}
.circle-container:nth-child(91) .circle {
  animation-delay: 1085ms;
}
.circle-container:nth-child(92) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-92;
  animation-duration: 36932ms;
  animation-delay: 30055ms;
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(61vw, 105vh, 0);
  }
  to {
    transform: translate3d(87vw, -108vh, 0);
  }
}
.circle-container:nth-child(92) .circle {
  animation-delay: 390ms;
}
.circle-container:nth-child(93) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-93;
  animation-duration: 31090ms;
  animation-delay: 3478ms;
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(39vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -118vh, 0);
  }
}
.circle-container:nth-child(93) .circle {
  animation-delay: 2685ms;
}
.circle-container:nth-child(94) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-94;
  animation-duration: 35729ms;
  animation-delay: 8336ms;
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(18vw, 107vh, 0);
  }
  to {
    transform: translate3d(5vw, -123vh, 0);
  }
}
.circle-container:nth-child(94) .circle {
  animation-delay: 985ms;
}
.circle-container:nth-child(95) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-95;
  animation-duration: 36773ms;
  animation-delay: 30262ms;
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(23vw, 110vh, 0);
  }
  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}
.circle-container:nth-child(95) .circle {
  animation-delay: 2980ms;
}
.circle-container:nth-child(96) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-96;
  animation-duration: 33849ms;
  animation-delay: 12993ms;
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(69vw, 102vh, 0);
  }
  to {
    transform: translate3d(6vw, -126vh, 0);
  }
}
.circle-container:nth-child(96) .circle {
  animation-delay: 3423ms;
}
.circle-container:nth-child(97) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-97;
  animation-duration: 29341ms;
  animation-delay: 31585ms;
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(51vw, 103vh, 0);
  }
  to {
    transform: translate3d(61vw, -128vh, 0);
  }
}
.circle-container:nth-child(97) .circle {
  animation-delay: 155ms;
}
.circle-container:nth-child(98) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-98;
  animation-duration: 31101ms;
  animation-delay: 11460ms;
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(69vw, 106vh, 0);
  }
  to {
    transform: translate3d(32vw, -114vh, 0);
  }
}
.circle-container:nth-child(98) .circle {
  animation-delay: 1205ms;
}
.circle-container:nth-child(99) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-99;
  animation-duration: 35277ms;
  animation-delay: 14787ms;
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(53vw, 102vh, 0);
  }
  to {
    transform: translate3d(3vw, -118vh, 0);
  }
}
.circle-container:nth-child(99) .circle {
  animation-delay: 760ms;
}
.circle-container:nth-child(100) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-100;
  animation-duration: 30340ms;
  animation-delay: 24440ms;
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(77vw, 104vh, 0);
  }
  to {
    transform: translate3d(10vw, -113vh, 0);
  }
}
.circle-container:nth-child(100) .circle {
  animation-delay: 1256ms;
}
.circle-container:nth-child(101) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-101;
  animation-duration: 30447ms;
  animation-delay: 8948ms;
}
@keyframes move-frames-101 {
  from {
    transform: translate3d(43vw, 103vh, 0);
  }
  to {
    transform: translate3d(60vw, -104vh, 0);
  }
}
.circle-container:nth-child(101) .circle {
  animation-delay: 1157ms;
}
.circle-container:nth-child(102) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-102;
  animation-duration: 29051ms;
  animation-delay: 27425ms;
}
@keyframes move-frames-102 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(99vw, -126vh, 0);
  }
}
.circle-container:nth-child(102) .circle {
  animation-delay: 2159ms;
}
.circle-container:nth-child(103) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-103;
  animation-duration: 32596ms;
  animation-delay: 3864ms;
}
@keyframes move-frames-103 {
  from {
    transform: translate3d(50vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -126vh, 0);
  }
}
.circle-container:nth-child(103) .circle {
  animation-delay: 2872ms;
}
.circle-container:nth-child(104) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-104;
  animation-duration: 28481ms;
  animation-delay: 26024ms;
}
@keyframes move-frames-104 {
  from {
    transform: translate3d(7vw, 110vh, 0);
  }
  to {
    transform: translate3d(34vw, -113vh, 0);
  }
}
.circle-container:nth-child(104) .circle {
  animation-delay: 1124ms;
}
.circle-container:nth-child(105) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-105;
  animation-duration: 33667ms;
  animation-delay: 14200ms;
}
@keyframes move-frames-105 {
  from {
    transform: translate3d(69vw, 107vh, 0);
  }
  to {
    transform: translate3d(58vw, -127vh, 0);
  }
}
.circle-container:nth-child(105) .circle {
  animation-delay: 3623ms;
}
.circle-container:nth-child(106) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-106;
  animation-duration: 28983ms;
  animation-delay: 12141ms;
}
@keyframes move-frames-106 {
  from {
    transform: translate3d(3vw, 105vh, 0);
  }
  to {
    transform: translate3d(56vw, -115vh, 0);
  }
}
.circle-container:nth-child(106) .circle {
  animation-delay: 1970ms;
}
.circle-container:nth-child(107) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-107;
  animation-duration: 30853ms;
  animation-delay: 33095ms;
}
@keyframes move-frames-107 {
  from {
    transform: translate3d(100vw, 109vh, 0);
  }
  to {
    transform: translate3d(27vw, -113vh, 0);
  }
}
.circle-container:nth-child(107) .circle {
  animation-delay: 3193ms;
}
.circle-container:nth-child(108) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-108;
  animation-duration: 32850ms;
  animation-delay: 4199ms;
}
@keyframes move-frames-108 {
  from {
    transform: translate3d(32vw, 102vh, 0);
  }
  to {
    transform: translate3d(37vw, -125vh, 0);
  }
}
.circle-container:nth-child(108) .circle {
  animation-delay: 201ms;
}
.circle-container:nth-child(109) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-109;
  animation-duration: 30408ms;
  animation-delay: 24574ms;
}
@keyframes move-frames-109 {
  from {
    transform: translate3d(16vw, 102vh, 0);
  }
  to {
    transform: translate3d(60vw, -107vh, 0);
  }
}
.circle-container:nth-child(109) .circle {
  animation-delay: 2811ms;
}
.circle-container:nth-child(110) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-110;
  animation-duration: 34722ms;
  animation-delay: 31166ms;
}
@keyframes move-frames-110 {
  from {
    transform: translate3d(86vw, 109vh, 0);
  }
  to {
    transform: translate3d(16vw, -126vh, 0);
  }
}
.circle-container:nth-child(110) .circle {
  animation-delay: 2535ms;
}
.circle-container:nth-child(111) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-111;
  animation-duration: 36014ms;
  animation-delay: 16558ms;
}
@keyframes move-frames-111 {
  from {
    transform: translate3d(70vw, 102vh, 0);
  }
  to {
    transform: translate3d(43vw, -107vh, 0);
  }
}
.circle-container:nth-child(111) .circle {
  animation-delay: 2732ms;
}
.circle-container:nth-child(112) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-112;
  animation-duration: 33698ms;
  animation-delay: 13437ms;
}
@keyframes move-frames-112 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
.circle-container:nth-child(112) .circle {
  animation-delay: 1809ms;
}
.circle-container:nth-child(113) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-113;
  animation-duration: 33952ms;
  animation-delay: 9052ms;
}
@keyframes move-frames-113 {
  from {
    transform: translate3d(70vw, 101vh, 0);
  }
  to {
    transform: translate3d(39vw, -110vh, 0);
  }
}
.circle-container:nth-child(113) .circle {
  animation-delay: 3860ms;
}
.circle-container:nth-child(114) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-114;
  animation-duration: 33025ms;
  animation-delay: 26408ms;
}
@keyframes move-frames-114 {
  from {
    transform: translate3d(92vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -129vh, 0);
  }
}
.circle-container:nth-child(114) .circle {
  animation-delay: 472ms;
}
.circle-container:nth-child(115) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-115;
  animation-duration: 31981ms;
  animation-delay: 19237ms;
}
@keyframes move-frames-115 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(83vw, -111vh, 0);
  }
}
.circle-container:nth-child(115) .circle {
  animation-delay: 800ms;
}
.circle-container:nth-child(116) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-116;
  animation-duration: 36056ms;
  animation-delay: 13045ms;
}
@keyframes move-frames-116 {
  from {
    transform: translate3d(86vw, 102vh, 0);
  }
  to {
    transform: translate3d(35vw, -132vh, 0);
  }
}
.circle-container:nth-child(116) .circle {
  animation-delay: 2116ms;
}
.circle-container:nth-child(117) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-117;
  animation-duration: 33990ms;
  animation-delay: 20295ms;
}
@keyframes move-frames-117 {
  from {
    transform: translate3d(21vw, 108vh, 0);
  }
  to {
    transform: translate3d(9vw, -134vh, 0);
  }
}
.circle-container:nth-child(117) .circle {
  animation-delay: 1697ms;
}
.circle-container:nth-child(118) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-118;
  animation-duration: 35987ms;
  animation-delay: 15350ms;
}
@keyframes move-frames-118 {
  from {
    transform: translate3d(89vw, 101vh, 0);
  }
  to {
    transform: translate3d(30vw, -131vh, 0);
  }
}
.circle-container:nth-child(118) .circle {
  animation-delay: 3912ms;
}
.circle-container:nth-child(119) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-119;
  animation-duration: 33714ms;
  animation-delay: 32625ms;
}
@keyframes move-frames-119 {
  from {
    transform: translate3d(88vw, 106vh, 0);
  }
  to {
    transform: translate3d(82vw, -116vh, 0);
  }
}
.circle-container:nth-child(119) .circle {
  animation-delay: 3772ms;
}
.circle-container:nth-child(120) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-120;
  animation-duration: 31871ms;
  animation-delay: 22955ms;
}
@keyframes move-frames-120 {
  from {
    transform: translate3d(38vw, 107vh, 0);
  }
  to {
    transform: translate3d(3vw, -118vh, 0);
  }
}
.circle-container:nth-child(120) .circle {
  animation-delay: 3168ms;
}
.circle-container:nth-child(121) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-121;
  animation-duration: 31008ms;
  animation-delay: 33753ms;
}
@keyframes move-frames-121 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }
  to {
    transform: translate3d(93vw, -120vh, 0);
  }
}
.circle-container:nth-child(121) .circle {
  animation-delay: 2394ms;
}
.circle-container:nth-child(122) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-122;
  animation-duration: 28515ms;
  animation-delay: 12606ms;
}
@keyframes move-frames-122 {
  from {
    transform: translate3d(80vw, 105vh, 0);
  }
  to {
    transform: translate3d(76vw, -132vh, 0);
  }
}
.circle-container:nth-child(122) .circle {
  animation-delay: 2153ms;
}
.circle-container:nth-child(123) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-123;
  animation-duration: 32448ms;
  animation-delay: 32437ms;
}
@keyframes move-frames-123 {
  from {
    transform: translate3d(43vw, 107vh, 0);
  }
  to {
    transform: translate3d(36vw, -122vh, 0);
  }
}
.circle-container:nth-child(123) .circle {
  animation-delay: 2694ms;
}
.circle-container:nth-child(124) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-124;
  animation-duration: 33129ms;
  animation-delay: 24906ms;
}
@keyframes move-frames-124 {
  from {
    transform: translate3d(62vw, 105vh, 0);
  }
  to {
    transform: translate3d(62vw, -123vh, 0);
  }
}
.circle-container:nth-child(124) .circle {
  animation-delay: 237ms;
}
.circle-container:nth-child(125) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-125;
  animation-duration: 30262ms;
  animation-delay: 16441ms;
}
@keyframes move-frames-125 {
  from {
    transform: translate3d(16vw, 102vh, 0);
  }
  to {
    transform: translate3d(62vw, -131vh, 0);
  }
}
.circle-container:nth-child(125) .circle {
  animation-delay: 2538ms;
}
.circle-container:nth-child(126) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-126;
  animation-duration: 31804ms;
  animation-delay: 20133ms;
}
@keyframes move-frames-126 {
  from {
    transform: translate3d(61vw, 106vh, 0);
  }
  to {
    transform: translate3d(31vw, -121vh, 0);
  }
}
.circle-container:nth-child(126) .circle {
  animation-delay: 3852ms;
}
.circle-container:nth-child(127) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-127;
  animation-duration: 32378ms;
  animation-delay: 13731ms;
}
@keyframes move-frames-127 {
  from {
    transform: translate3d(7vw, 105vh, 0);
  }
  to {
    transform: translate3d(19vw, -109vh, 0);
  }
}
.circle-container:nth-child(127) .circle {
  animation-delay: 2822ms;
}
.circle-container:nth-child(128) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-128;
  animation-duration: 29857ms;
  animation-delay: 16345ms;
}
@keyframes move-frames-128 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }
  to {
    transform: translate3d(49vw, -105vh, 0);
  }
}
.circle-container:nth-child(128) .circle {
  animation-delay: 1024ms;
}
.circle-container:nth-child(129) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-129;
  animation-duration: 32066ms;
  animation-delay: 14739ms;
}
@keyframes move-frames-129 {
  from {
    transform: translate3d(89vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -122vh, 0);
  }
}
.circle-container:nth-child(129) .circle {
  animation-delay: 829ms;
}
.circle-container:nth-child(130) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-130;
  animation-duration: 34962ms;
  animation-delay: 34609ms;
}
@keyframes move-frames-130 {
  from {
    transform: translate3d(67vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -107vh, 0);
  }
}
.circle-container:nth-child(130) .circle {
  animation-delay: 470ms;
}
.circle-container:nth-child(131) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-131;
  animation-duration: 34526ms;
  animation-delay: 31752ms;
}
@keyframes move-frames-131 {
  from {
    transform: translate3d(28vw, 102vh, 0);
  }
  to {
    transform: translate3d(76vw, -128vh, 0);
  }
}
.circle-container:nth-child(131) .circle {
  animation-delay: 3980ms;
}
.circle-container:nth-child(132) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-132;
  animation-duration: 32483ms;
  animation-delay: 27240ms;
}
@keyframes move-frames-132 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -112vh, 0);
  }
}
.circle-container:nth-child(132) .circle {
  animation-delay: 2111ms;
}
.circle-container:nth-child(133) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-133;
  animation-duration: 31333ms;
  animation-delay: 19224ms;
}
@keyframes move-frames-133 {
  from {
    transform: translate3d(87vw, 101vh, 0);
  }
  to {
    transform: translate3d(31vw, -124vh, 0);
  }
}
.circle-container:nth-child(133) .circle {
  animation-delay: 983ms;
}
.circle-container:nth-child(134) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-134;
  animation-duration: 28051ms;
  animation-delay: 31410ms;
}
@keyframes move-frames-134 {
  from {
    transform: translate3d(68vw, 101vh, 0);
  }
  to {
    transform: translate3d(35vw, -113vh, 0);
  }
}
.circle-container:nth-child(134) .circle {
  animation-delay: 2211ms;
}
.circle-container:nth-child(135) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-135;
  animation-duration: 30897ms;
  animation-delay: 15595ms;
}
@keyframes move-frames-135 {
  from {
    transform: translate3d(6vw, 106vh, 0);
  }
  to {
    transform: translate3d(8vw, -114vh, 0);
  }
}
.circle-container:nth-child(135) .circle {
  animation-delay: 212ms;
}
.circle-container:nth-child(136) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-136;
  animation-duration: 33498ms;
  animation-delay: 31949ms;
}
@keyframes move-frames-136 {
  from {
    transform: translate3d(22vw, 101vh, 0);
  }
  to {
    transform: translate3d(50vw, -107vh, 0);
  }
}
.circle-container:nth-child(136) .circle {
  animation-delay: 366ms;
}
.circle-container:nth-child(137) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-137;
  animation-duration: 32947ms;
  animation-delay: 10610ms;
}
@keyframes move-frames-137 {
  from {
    transform: translate3d(7vw, 110vh, 0);
  }
  to {
    transform: translate3d(42vw, -117vh, 0);
  }
}
.circle-container:nth-child(137) .circle {
  animation-delay: 2818ms;
}
.circle-container:nth-child(138) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-138;
  animation-duration: 29938ms;
  animation-delay: 13819ms;
}
@keyframes move-frames-138 {
  from {
    transform: translate3d(85vw, 101vh, 0);
  }
  to {
    transform: translate3d(87vw, -111vh, 0);
  }
}
.circle-container:nth-child(138) .circle {
  animation-delay: 3583ms;
}
.circle-container:nth-child(139) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-139;
  animation-duration: 35925ms;
  animation-delay: 36716ms;
}
@keyframes move-frames-139 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(18vw, -106vh, 0);
  }
}
.circle-container:nth-child(139) .circle {
  animation-delay: 3656ms;
}
.circle-container:nth-child(140) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-140;
  animation-duration: 28797ms;
  animation-delay: 12229ms;
}
@keyframes move-frames-140 {
  from {
    transform: translate3d(92vw, 110vh, 0);
  }
  to {
    transform: translate3d(21vw, -120vh, 0);
  }
}
.circle-container:nth-child(140) .circle {
  animation-delay: 1778ms;
}
.circle-container:nth-child(141) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-141;
  animation-duration: 35605ms;
  animation-delay: 1871ms;
}
@keyframes move-frames-141 {
  from {
    transform: translate3d(73vw, 104vh, 0);
  }
  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}
.circle-container:nth-child(141) .circle {
  animation-delay: 1852ms;
}
.circle-container:nth-child(142) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-142;
  animation-duration: 30905ms;
  animation-delay: 7748ms;
}
@keyframes move-frames-142 {
  from {
    transform: translate3d(52vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -126vh, 0);
  }
}
.circle-container:nth-child(142) .circle {
  animation-delay: 1826ms;
}
.circle-container:nth-child(143) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-143;
  animation-duration: 31167ms;
  animation-delay: 14743ms;
}
@keyframes move-frames-143 {
  from {
    transform: translate3d(100vw, 104vh, 0);
  }
  to {
    transform: translate3d(57vw, -112vh, 0);
  }
}
.circle-container:nth-child(143) .circle {
  animation-delay: 1377ms;
}
.circle-container:nth-child(144) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-144;
  animation-duration: 32806ms;
  animation-delay: 10933ms;
}
@keyframes move-frames-144 {
  from {
    transform: translate3d(19vw, 101vh, 0);
  }
  to {
    transform: translate3d(76vw, -123vh, 0);
  }
}
.circle-container:nth-child(144) .circle {
  animation-delay: 2484ms;
}
.circle-container:nth-child(145) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-145;
  animation-duration: 36804ms;
  animation-delay: 30206ms;
}
@keyframes move-frames-145 {
  from {
    transform: translate3d(16vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -126vh, 0);
  }
}
.circle-container:nth-child(145) .circle {
  animation-delay: 3418ms;
}
.circle-container:nth-child(146) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-146;
  animation-duration: 32905ms;
  animation-delay: 31177ms;
}
@keyframes move-frames-146 {
  from {
    transform: translate3d(99vw, 103vh, 0);
  }
  to {
    transform: translate3d(55vw, -122vh, 0);
  }
}
.circle-container:nth-child(146) .circle {
  animation-delay: 2237ms;
}
.circle-container:nth-child(147) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-147;
  animation-duration: 30465ms;
  animation-delay: 35242ms;
}
@keyframes move-frames-147 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(76vw, -106vh, 0);
  }
}
.circle-container:nth-child(147) .circle {
  animation-delay: 3840ms;
}
.circle-container:nth-child(148) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-148;
  animation-duration: 32763ms;
  animation-delay: 3868ms;
}
@keyframes move-frames-148 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(79vw, -111vh, 0);
  }
}
.circle-container:nth-child(148) .circle {
  animation-delay: 771ms;
}
.circle-container:nth-child(149) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-149;
  animation-duration: 35135ms;
  animation-delay: 16248ms;
}
@keyframes move-frames-149 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }
  to {
    transform: translate3d(63vw, -125vh, 0);
  }
}
.circle-container:nth-child(149) .circle {
  animation-delay: 3464ms;
}
.circle-container:nth-child(150) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-150;
  animation-duration: 36576ms;
  animation-delay: 31573ms;
}
@keyframes move-frames-150 {
  from {
    transform: translate3d(42vw, 103vh, 0);
  }
  to {
    transform: translate3d(1vw, -108vh, 0);
  }
}
.circle-container:nth-child(150) .circle {
  animation-delay: 2457ms;
}
.circle-container:nth-child(151) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-151;
  animation-duration: 31514ms;
  animation-delay: 28475ms;
}
@keyframes move-frames-151 {
  from {
    transform: translate3d(26vw, 103vh, 0);
  }
  to {
    transform: translate3d(97vw, -115vh, 0);
  }
}
.circle-container:nth-child(151) .circle {
  animation-delay: 2395ms;
}
.circle-container:nth-child(152) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-152;
  animation-duration: 29247ms;
  animation-delay: 35735ms;
}
@keyframes move-frames-152 {
  from {
    transform: translate3d(57vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -121vh, 0);
  }
}
.circle-container:nth-child(152) .circle {
  animation-delay: 1648ms;
}
.circle-container:nth-child(153) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-153;
  animation-duration: 35424ms;
  animation-delay: 31880ms;
}
@keyframes move-frames-153 {
  from {
    transform: translate3d(36vw, 103vh, 0);
  }
  to {
    transform: translate3d(94vw, -133vh, 0);
  }
}
.circle-container:nth-child(153) .circle {
  animation-delay: 670ms;
}
.circle-container:nth-child(154) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-154;
  animation-duration: 30401ms;
  animation-delay: 20780ms;
}
@keyframes move-frames-154 {
  from {
    transform: translate3d(85vw, 110vh, 0);
  }
  to {
    transform: translate3d(40vw, -120vh, 0);
  }
}
.circle-container:nth-child(154) .circle {
  animation-delay: 2488ms;
}
.circle-container:nth-child(155) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-155;
  animation-duration: 36060ms;
  animation-delay: 22428ms;
}
@keyframes move-frames-155 {
  from {
    transform: translate3d(87vw, 102vh, 0);
  }
  to {
    transform: translate3d(95vw, -104vh, 0);
  }
}
.circle-container:nth-child(155) .circle {
  animation-delay: 558ms;
}
.circle-container:nth-child(156) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-156;
  animation-duration: 35259ms;
  animation-delay: 7394ms;
}
@keyframes move-frames-156 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }
  to {
    transform: translate3d(47vw, -125vh, 0);
  }
}
.circle-container:nth-child(156) .circle {
  animation-delay: 2827ms;
}
.circle-container:nth-child(157) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-157;
  animation-duration: 28862ms;
  animation-delay: 13057ms;
}
@keyframes move-frames-157 {
  from {
    transform: translate3d(81vw, 104vh, 0);
  }
  to {
    transform: translate3d(75vw, -133vh, 0);
  }
}
.circle-container:nth-child(157) .circle {
  animation-delay: 3553ms;
}
.circle-container:nth-child(158) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-158;
  animation-duration: 31883ms;
  animation-delay: 1735ms;
}
@keyframes move-frames-158 {
  from {
    transform: translate3d(45vw, 110vh, 0);
  }
  to {
    transform: translate3d(50vw, -113vh, 0);
  }
}
.circle-container:nth-child(158) .circle {
  animation-delay: 1304ms;
}
.circle-container:nth-child(159) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-159;
  animation-duration: 35017ms;
  animation-delay: 34567ms;
}
@keyframes move-frames-159 {
  from {
    transform: translate3d(54vw, 106vh, 0);
  }
  to {
    transform: translate3d(92vw, -129vh, 0);
  }
}
.circle-container:nth-child(159) .circle {
  animation-delay: 2565ms;
}
.circle-container:nth-child(160) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-160;
  animation-duration: 29248ms;
  animation-delay: 34067ms;
}
@keyframes move-frames-160 {
  from {
    transform: translate3d(51vw, 107vh, 0);
  }
  to {
    transform: translate3d(23vw, -128vh, 0);
  }
}
.circle-container:nth-child(160) .circle {
  animation-delay: 65ms;
}
.circle-container:nth-child(161) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-161;
  animation-duration: 37000ms;
  animation-delay: 57ms;
}
@keyframes move-frames-161 {
  from {
    transform: translate3d(61vw, 104vh, 0);
  }
  to {
    transform: translate3d(68vw, -126vh, 0);
  }
}
.circle-container:nth-child(161) .circle {
  animation-delay: 2944ms;
}
.circle-container:nth-child(162) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-162;
  animation-duration: 36662ms;
  animation-delay: 2003ms;
}
@keyframes move-frames-162 {
  from {
    transform: translate3d(36vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -129vh, 0);
  }
}
.circle-container:nth-child(162) .circle {
  animation-delay: 3403ms;
}
.circle-container:nth-child(163) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-163;
  animation-duration: 28218ms;
  animation-delay: 32366ms;
}
@keyframes move-frames-163 {
  from {
    transform: translate3d(84vw, 103vh, 0);
  }
  to {
    transform: translate3d(89vw, -109vh, 0);
  }
}
.circle-container:nth-child(163) .circle {
  animation-delay: 951ms;
}
.circle-container:nth-child(164) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-164;
  animation-duration: 33033ms;
  animation-delay: 17223ms;
}
@keyframes move-frames-164 {
  from {
    transform: translate3d(26vw, 108vh, 0);
  }
  to {
    transform: translate3d(75vw, -126vh, 0);
  }
}
.circle-container:nth-child(164) .circle {
  animation-delay: 924ms;
}
.circle-container:nth-child(165) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-165;
  animation-duration: 35337ms;
  animation-delay: 8523ms;
}
@keyframes move-frames-165 {
  from {
    transform: translate3d(68vw, 109vh, 0);
  }
  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}
.circle-container:nth-child(165) .circle {
  animation-delay: 3713ms;
}
.circle-container:nth-child(166) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-166;
  animation-duration: 28610ms;
  animation-delay: 20279ms;
}
@keyframes move-frames-166 {
  from {
    transform: translate3d(69vw, 110vh, 0);
  }
  to {
    transform: translate3d(95vw, -126vh, 0);
  }
}
.circle-container:nth-child(166) .circle {
  animation-delay: 3050ms;
}
.circle-container:nth-child(167) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-167;
  animation-duration: 32644ms;
  animation-delay: 19021ms;
}
@keyframes move-frames-167 {
  from {
    transform: translate3d(95vw, 107vh, 0);
  }
  to {
    transform: translate3d(98vw, -127vh, 0);
  }
}
.circle-container:nth-child(167) .circle {
  animation-delay: 371ms;
}
.circle-container:nth-child(168) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-168;
  animation-duration: 28598ms;
  animation-delay: 31850ms;
}
@keyframes move-frames-168 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(21vw, -111vh, 0);
  }
}
.circle-container:nth-child(168) .circle {
  animation-delay: 998ms;
}
.circle-container:nth-child(169) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-169;
  animation-duration: 32894ms;
  animation-delay: 14484ms;
}
@keyframes move-frames-169 {
  from {
    transform: translate3d(91vw, 103vh, 0);
  }
  to {
    transform: translate3d(30vw, -125vh, 0);
  }
}
.circle-container:nth-child(169) .circle {
  animation-delay: 1239ms;
}
.circle-container:nth-child(170) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-170;
  animation-duration: 33126ms;
  animation-delay: 16899ms;
}
@keyframes move-frames-170 {
  from {
    transform: translate3d(22vw, 104vh, 0);
  }
  to {
    transform: translate3d(24vw, -119vh, 0);
  }
}
.circle-container:nth-child(170) .circle {
  animation-delay: 397ms;
}
.circle-container:nth-child(171) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-171;
  animation-duration: 33724ms;
  animation-delay: 21328ms;
}
@keyframes move-frames-171 {
  from {
    transform: translate3d(32vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -129vh, 0);
  }
}
.circle-container:nth-child(171) .circle {
  animation-delay: 1097ms;
}
.circle-container:nth-child(172) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-172;
  animation-duration: 35704ms;
  animation-delay: 6212ms;
}
@keyframes move-frames-172 {
  from {
    transform: translate3d(33vw, 110vh, 0);
  }
  to {
    transform: translate3d(3vw, -122vh, 0);
  }
}
.circle-container:nth-child(172) .circle {
  animation-delay: 524ms;
}
.circle-container:nth-child(173) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-173;
  animation-duration: 28633ms;
  animation-delay: 2161ms;
}
@keyframes move-frames-173 {
  from {
    transform: translate3d(68vw, 105vh, 0);
  }
  to {
    transform: translate3d(87vw, -117vh, 0);
  }
}
.circle-container:nth-child(173) .circle {
  animation-delay: 550ms;
}
.circle-container:nth-child(174) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-174;
  animation-duration: 29116ms;
  animation-delay: 31594ms;
}
@keyframes move-frames-174 {
  from {
    transform: translate3d(96vw, 102vh, 0);
  }
  to {
    transform: translate3d(96vw, -117vh, 0);
  }
}
.circle-container:nth-child(174) .circle {
  animation-delay: 1701ms;
}
.circle-container:nth-child(175) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-175;
  animation-duration: 35479ms;
  animation-delay: 15883ms;
}
@keyframes move-frames-175 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -110vh, 0);
  }
}
.circle-container:nth-child(175) .circle {
  animation-delay: 639ms;
}
.circle-container:nth-child(176) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-176;
  animation-duration: 30678ms;
  animation-delay: 2780ms;
}
@keyframes move-frames-176 {
  from {
    transform: translate3d(39vw, 107vh, 0);
  }
  to {
    transform: translate3d(47vw, -125vh, 0);
  }
}
.circle-container:nth-child(176) .circle {
  animation-delay: 1819ms;
}
.circle-container:nth-child(177) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-177;
  animation-duration: 35096ms;
  animation-delay: 32060ms;
}
@keyframes move-frames-177 {
  from {
    transform: translate3d(56vw, 109vh, 0);
  }
  to {
    transform: translate3d(65vw, -138vh, 0);
  }
}
.circle-container:nth-child(177) .circle {
  animation-delay: 3099ms;
}
.circle-container:nth-child(178) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-178;
  animation-duration: 36991ms;
  animation-delay: 22168ms;
}
@keyframes move-frames-178 {
  from {
    transform: translate3d(94vw, 108vh, 0);
  }
  to {
    transform: translate3d(7vw, -122vh, 0);
  }
}
.circle-container:nth-child(178) .circle {
  animation-delay: 2960ms;
}
.circle-container:nth-child(179) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-179;
  animation-duration: 33294ms;
  animation-delay: 2337ms;
}
@keyframes move-frames-179 {
  from {
    transform: translate3d(63vw, 103vh, 0);
  }
  to {
    transform: translate3d(29vw, -123vh, 0);
  }
}
.circle-container:nth-child(179) .circle {
  animation-delay: 2221ms;
}
.circle-container:nth-child(180) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-180;
  animation-duration: 30076ms;
  animation-delay: 29094ms;
}
@keyframes move-frames-180 {
  from {
    transform: translate3d(78vw, 108vh, 0);
  }
  to {
    transform: translate3d(72vw, -133vh, 0);
  }
}
.circle-container:nth-child(180) .circle {
  animation-delay: 1902ms;
}
.circle-container:nth-child(181) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-181;
  animation-duration: 30451ms;
  animation-delay: 12539ms;
}
@keyframes move-frames-181 {
  from {
    transform: translate3d(67vw, 106vh, 0);
  }
  to {
    transform: translate3d(30vw, -107vh, 0);
  }
}
.circle-container:nth-child(181) .circle {
  animation-delay: 2088ms;
}
.circle-container:nth-child(182) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-182;
  animation-duration: 32230ms;
  animation-delay: 9888ms;
}
@keyframes move-frames-182 {
  from {
    transform: translate3d(68vw, 110vh, 0);
  }
  to {
    transform: translate3d(34vw, -131vh, 0);
  }
}
.circle-container:nth-child(182) .circle {
  animation-delay: 3127ms;
}
.circle-container:nth-child(183) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-183;
  animation-duration: 30492ms;
  animation-delay: 2980ms;
}
@keyframes move-frames-183 {
  from {
    transform: translate3d(89vw, 107vh, 0);
  }
  to {
    transform: translate3d(55vw, -109vh, 0);
  }
}
.circle-container:nth-child(183) .circle {
  animation-delay: 2236ms;
}
.circle-container:nth-child(184) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-184;
  animation-duration: 33141ms;
  animation-delay: 28307ms;
}
@keyframes move-frames-184 {
  from {
    transform: translate3d(11vw, 107vh, 0);
  }
  to {
    transform: translate3d(44vw, -111vh, 0);
  }
}
.circle-container:nth-child(184) .circle {
  animation-delay: 522ms;
}
.circle-container:nth-child(185) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-185;
  animation-duration: 29697ms;
  animation-delay: 2809ms;
}
@keyframes move-frames-185 {
  from {
    transform: translate3d(44vw, 102vh, 0);
  }
  to {
    transform: translate3d(34vw, -117vh, 0);
  }
}
.circle-container:nth-child(185) .circle {
  animation-delay: 3300ms;
}
.circle-container:nth-child(186) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-186;
  animation-duration: 30331ms;
  animation-delay: 15486ms;
}
@keyframes move-frames-186 {
  from {
    transform: translate3d(52vw, 110vh, 0);
  }
  to {
    transform: translate3d(31vw, -122vh, 0);
  }
}
.circle-container:nth-child(186) .circle {
  animation-delay: 2996ms;
}
.circle-container:nth-child(187) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-187;
  animation-duration: 36511ms;
  animation-delay: 26123ms;
}
@keyframes move-frames-187 {
  from {
    transform: translate3d(83vw, 110vh, 0);
  }
  to {
    transform: translate3d(71vw, -132vh, 0);
  }
}
.circle-container:nth-child(187) .circle {
  animation-delay: 2437ms;
}
.circle-container:nth-child(188) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-188;
  animation-duration: 35003ms;
  animation-delay: 14307ms;
}
@keyframes move-frames-188 {
  from {
    transform: translate3d(73vw, 108vh, 0);
  }
  to {
    transform: translate3d(42vw, -122vh, 0);
  }
}
.circle-container:nth-child(188) .circle {
  animation-delay: 2599ms;
}
.circle-container:nth-child(189) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-189;
  animation-duration: 34033ms;
  animation-delay: 3071ms;
}
@keyframes move-frames-189 {
  from {
    transform: translate3d(78vw, 104vh, 0);
  }
  to {
    transform: translate3d(29vw, -123vh, 0);
  }
}
.circle-container:nth-child(189) .circle {
  animation-delay: 2856ms;
}
.circle-container:nth-child(190) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-190;
  animation-duration: 33696ms;
  animation-delay: 22301ms;
}
@keyframes move-frames-190 {
  from {
    transform: translate3d(92vw, 110vh, 0);
  }
  to {
    transform: translate3d(4vw, -123vh, 0);
  }
}
.circle-container:nth-child(190) .circle {
  animation-delay: 1011ms;
}
.circle-container:nth-child(191) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-191;
  animation-duration: 35213ms;
  animation-delay: 10125ms;
}
@keyframes move-frames-191 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }
  to {
    transform: translate3d(30vw, -120vh, 0);
  }
}
.circle-container:nth-child(191) .circle {
  animation-delay: 775ms;
}
.circle-container:nth-child(192) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-192;
  animation-duration: 32187ms;
  animation-delay: 28104ms;
}
@keyframes move-frames-192 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }
  to {
    transform: translate3d(14vw, -122vh, 0);
  }
}
.circle-container:nth-child(192) .circle {
  animation-delay: 134ms;
}
.circle-container:nth-child(193) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-193;
  animation-duration: 36080ms;
  animation-delay: 19382ms;
}
@keyframes move-frames-193 {
  from {
    transform: translate3d(44vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -122vh, 0);
  }
}
.circle-container:nth-child(193) .circle {
  animation-delay: 719ms;
}
.circle-container:nth-child(194) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-194;
  animation-duration: 34620ms;
  animation-delay: 15166ms;
}
@keyframes move-frames-194 {
  from {
    transform: translate3d(41vw, 110vh, 0);
  }
  to {
    transform: translate3d(5vw, -115vh, 0);
  }
}
.circle-container:nth-child(194) .circle {
  animation-delay: 2158ms;
}
.circle-container:nth-child(195) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-195;
  animation-duration: 36944ms;
  animation-delay: 17969ms;
}
@keyframes move-frames-195 {
  from {
    transform: translate3d(60vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -112vh, 0);
  }
}
.circle-container:nth-child(195) .circle {
  animation-delay: 3191ms;
}
.circle-container:nth-child(196) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-196;
  animation-duration: 36246ms;
  animation-delay: 15730ms;
}
@keyframes move-frames-196 {
  from {
    transform: translate3d(12vw, 108vh, 0);
  }
  to {
    transform: translate3d(75vw, -109vh, 0);
  }
}
.circle-container:nth-child(196) .circle {
  animation-delay: 3809ms;
}
.circle-container:nth-child(197) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-197;
  animation-duration: 29738ms;
  animation-delay: 18752ms;
}
@keyframes move-frames-197 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(41vw, -110vh, 0);
  }
}
.circle-container:nth-child(197) .circle {
  animation-delay: 2958ms;
}
.circle-container:nth-child(198) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-198;
  animation-duration: 28820ms;
  animation-delay: 10352ms;
}
@keyframes move-frames-198 {
  from {
    transform: translate3d(53vw, 101vh, 0);
  }
  to {
    transform: translate3d(34vw, -129vh, 0);
  }
}
.circle-container:nth-child(198) .circle {
  animation-delay: 2241ms;
}
.circle-container:nth-child(199) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-199;
  animation-duration: 29811ms;
  animation-delay: 30984ms;
}
@keyframes move-frames-199 {
  from {
    transform: translate3d(3vw, 108vh, 0);
  }
  to {
    transform: translate3d(54vw, -135vh, 0);
  }
}
.circle-container:nth-child(199) .circle {
  animation-delay: 2627ms;
}
.circle-container:nth-child(200) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-200;
  animation-duration: 29444ms;
  animation-delay: 221ms;
}
@keyframes move-frames-200 {
  from {
    transform: translate3d(25vw, 101vh, 0);
  }
  to {
    transform: translate3d(48vw, -131vh, 0);
  }
}
.circle-container:nth-child(200) .circle {
  animation-delay: 3323ms;
}

.message {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: white;
  font-family: "Josefin Slab", serif;
  line-height: 27px;
  font-size: 18px;
  text-align: right;
  pointer-events: none;
  animation: message-frames 1.5s ease 5s forwards;
  opacity: 0;
}
@keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
